<style>
.PlanejamentoContratoFormAcoesConfiguracao .container {
  padding: 16px;
}
.PlanejamentoContratoFormAcoesConfiguracao div.v-card {
  min-height: 168px;
}

</style>
<template>
  <v-container fluid grid-list-md
    class="PlanejamentoContratoFormAcoesConfiguracao px-0 pb-0 pt-5">
    <v-row>
      <v-col cols="12" class="py-0">
        <div class="title-float accent--text">
          {{$tc('label.configuracao', 1)}}
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <v-card>
          <v-card-title>
            {{$tc('label.pagamento', 1)}}
          </v-card-title>
          <v-container fluid grid-list-md>
            <v-row>
              <v-col cols="12" md="3">
                <v-autocomplete
                  id="pagamento_retroativo"
                  class="custom-autocomplete"
                  v-model="passoApuracao.pagamentoRetroativo"
                  :no-data-text="`${$tc('message.nenhum_registro', 1)} *`"
                  :items="pagamentosRetroativo"
                  :label="`${$tc('label.pagamento_retroativo',2)} *`"
                  item-text="nome"
                  item-value="value"
                  @change="pagamentoRetroativoSelecionado(passoApuracao.pagamentoRetroativo)"
                  :rules="[rules.required]"
                  :disabled="somenteLeitura">
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="3" style="margin-top: -10px;">
                <v-menu
                  ref="menuMesPesquisa"
                  :close-on-content-click="false"
                  v-model="menuMesPesquisa"
                  id="periodo_retroativo"
                  name="periodo_retroativo"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  :return-value.sync="passoApuracao.periodoRetroativo"
                  max-width="290px"
                  min-width="290px"
                  >
                  <template v-slot:activator="{ on }">
                    <v-combobox
                      v-model="passoApuracao.periodoRetroativo"
                      multiple
                      chips
                      deletable-chips
                      clearable
                      :disabled="desabilitarPeriodoPagamento"
                      :label="`${$tc('label.periodo_retroativo')} *`"
                      prepend-icon="event"
                      :rules="[required]"
                      v-on="on"
                    ></v-combobox>
                  </template>
                  <v-date-picker locale="pt-br" color="primary" type="month" multiple v-model="passoApuracao.periodoRetroativo">
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="menuMesPesquisa = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menuMesPesquisa.save(passoApuracao.periodoRetroativo)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="3">
               <v-autocomplete
                  id="forma_pagamento"
                  class="custom-autocomplete"
                  v-model="passoApuracao.formaBonificacao"
                  clearable
                  :no-data-text="`${$tc('message.nenhum_registro', 1)} *`"
                  :items="listaFormaPagamento"
                  :label="`${$tc('label.forma_pagamento',2)} *`"
                  item-text="nome"
                  item-value="value"
                  :rules="[rules.required]"
                  @change="validaTipoPagamento(passoApuracao.formaBonificacao)"
                  :disabled="somenteLeitura || desabilitaFormaPagamento">
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="3">
                <v-autocomplete
                  id="tipo_pagamento"
                  class="custom-autocomplete"
                  v-model="passoApuracao.tipoPagamento"
                  clearable
                  :no-data-text="$tc('message.nenhum_registro', 1)"
                  :items="listaPagamentoPermitidos"
                  :label="`${$tc('label.tipo_pagamento',2)} *`"
                  item-text="nome"
                  item-value="valor"
                  :rules="[rules.required]"
                  :disabled="somenteLeitura">
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field
                  id="observacao"
                  name="observacao"
                  v-model="passoApuracao.justificativa"
                  :label="$tc('label.observacao', 2)"
                  :disabled="somenteLeitura"
                  :counter="150"
                  maxlength="150"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card style="height: 100%;">
          <v-card-title>
            {{$tc('label.apuracao', 1)}}
          </v-card-title>
          <v-container fluid grid-list-md>
            <v-row>
              <v-col cols="12" md="4">
                <v-menu
                  ref="dta_inicio"
                  :close-on-content-click="false"
                  v-model="menuDataInicio"
                  id="dta_inicio"
                  name="dta_inicio"
                  :nudge-right="40"
                  :readonly="somenteLeitura"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-on="on"
                      ref="dataInicio"
                      class="Form-text__invalid"
                      required
                      :return-masked-value="true"
                      v-mask="'##-##-####'"
                      :rules="[rules.required]"
                      v-model="dataInicioFormatada"
                      :label="`${$t('label.data_inicio')} *`"
                      prepend-icon="event">
                    </v-text-field>
                  </template>
                  <v-date-picker
                    locale="pt-br"
                    color="primary"
                    :min="objetoPassoGeral.dtaVigenciaInicio"
                    :max="objetoPassoGeral.dtaVigenciaFim"
                    :show-current="dataInicioPeriodo"
                    v-model="dataInicioPicker"
                    @input="menuDataInicio = false">
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="4">
                <v-menu
                  ref="dta_fim"
                  :close-on-content-click="false"
                  v-model="menuDataFim"
                  id="dta_fim"
                  name="dta_fim"
                  :nudge-right="40"
                  :readonly="somenteLeitura"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-on="on"
                      :disabled="somenteLeitura || desabilitarDataFim"
                      ref="dta_fim"
                      class="Form-text__invalid"
                      required
                      :return-masked-value="true"
                      v-mask="'##-##-####'"
                      :rules="[rules.required]"
                      v-model="dataFimFormatada"
                      :label="`${$t('label.data_fim_encerramento')} *`"
                      prepend-icon="event">
                    </v-text-field>
                  </template>
                  <v-date-picker
                    locale="pt-br"
                    color="primary"
                    :min="objetoPassoGeral.dtaVigenciaInicio"
                    :max="objetoPassoGeral.dtaVigenciaFim"
                    :show-current="this.passoApuracao.dtaInicio"
                    v-model="dataFimPicker"
                    @input="menuDataFim = false">
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="4">
                <v-autocomplete
                  id="periodo_apuracao"
                  class="custom-autocomplete"
                  v-model="passoApuracao.periodoApuracao"
                  :no-data-text="$tc('message.nenhum_registro', 1)"
                  :items="listaPeriodosApuracao"
                  :label="`${$tc('label.periodo_apuracao',1)} *`"
                  item-text="nome"
                  item-value="value"
                  :disabled="true">
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="4">
                <v-autocomplete
                  class="custom-autocomplete"
                  v-model="passoApuracao.condicao"
                  clearable
                  :no-data-text="$tc('message.nenhum_registro', 1)"
                  :items="listaCondicao"
                  :label="`${$tc('label.condicao',1)} *`"
                  item-text="nome"
                  item-value="valor"
                  :rules="[rules.requiredCondicao]"
                  :disabled="somenteLeitura"
                  @change="condicaoAlterada(passoApuracao.condicao)">
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="4">
                <v-autocomplete
                  class="custom-autocomplete"
                  v-model="passoApuracao.baseCalculo"
                  clearable
                  :no-data-text="$tc('message.nenhum_registro', 1)"
                  :items="listaBaseCalculo"
                  :label="$tc('title.base_calculo',1)"
                  item-text="nome"
                  item-value="valor"
                  :disabled="true">
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="4">
                <v-autocomplete
                  class="custom-autocomplete"
                  v-model="passoApuracao.periodoComparacao"
                  clearable
                  :no-data-text="$tc('message.nenhum_registro', 1)"
                  :items="listaPeriodosComparacao"
                  :label="`${$tc('label.periodo_comparacao',1)} *`"
                  item-text="nome"
                  item-value="valor"
                  :rules="[rules.requiredPeriodoCondicao]"
                  :disabled="somenteLeitura || desabilitaIntervalo">
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="4">
                <input-money
                  v-if="ehFixo"
                  :label="`${$tc('label.valor',1)} *`"
                  v-model="passoApuracao.valorAcao"
                  @input="alteraValor(passoApuracao.valorAcao)"
                  :rules="[rules.required, v => rules.valorMinimo(v, 0.01)]"
                  :disabled="somenteLeitura"
                  class="v-text-field"/>
                <v-text-field
                  v-else
                  :label="`${$tc('label.valor',1)} *`"
                  v-model="passoApuracao.valorAcao"
                  @input="alteraValor(passoApuracao.valorAcao)"
                  :rules="[rules.required, v => rules.valorMinimo(v, 0.01)]"
                  :disabled="somenteLeitura"
                  type="Number"
                  min="0"
                  :suffix="'%'"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
        <v-col cols="12" md="6">
          <template>
            <planejamento-acao-form-apuracao-intervalo
            :somenteLeitura="somenteLeitura"
            :calculo-volume="calculoVolume"
            :destacar-meta="destacarMeta"
            :verbaPercentual="verbaPercentual"
            :sigla="sigla"
            :pagamento-retroativo="passoApuracao.pagamentoRetroativo"
            :desabilitaAdd ="desabilitaIntervalo"
            :titulo-intervalo="$tc('title.intervalo_crecimento')"
            :metas="passoApuracao.metas"
            @retirarDestaque="() => destacarMeta = false"
            @PlanejamentoAcaoFormApuracaoIntervalo__maiorValorMetaAteFixoPercentual="alterarCoeficienteCalculoValorTotalAcao"/>
          </template>
      </v-col>
    </v-row>
    <confirm ref="confirm" :message="dialog.message" :persistent="true" @agree="dialog.agree" @disagree="dialog.disagree"></confirm>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import {
  parseDateYYYYMMDD,
  formatDateDDMMYYYY,
} from '../../../../produto/common/functions/date-utils';
import MetadadosContainerLayout from '../../../../produto/shared-components/metadados/MetadadosContainerLayout';
import MetadadosCampoDinamicoData from '../../../../produto/shared-components/metadados/campos-dinamicos/MetadadosCampoDinamicoData';
import PlanejamentoAcaoFormApuracaoIntervalo from './PlanejamentoAcaoFormApuracaoIntervalo';
import PlanejamentoContratoFormAcoesApuracaoDefinicao from './PlanejamentoContratoFormAcoesApuracaoDefinicao';
import planejamentoContratoCalculoValorTotalAcaoMixin from './planejamentoContratoCalculoValorTotalAcaoMixin';
import InputMoney from '../../../../produto/shared-components/inputs/InputMoney';
import Confirm from '../../../../produto/shared-components/vuetify/dialog/Confirm';

export default {
  props: {
    somenteLeitura: Boolean,
    passoApuracao: Object,
    edicao: Boolean,
    novo: Boolean,
    copiaAcao: Boolean,
    configuracaoAcao: Object,
    configuracaoContrato: {
      type: Object,
      default: () => {},
    },
    passoGeral: {
      type: Object,
      required: true,
    },
    objetoPassoGeral: {
      type: Object,
      required: true,
    },
  },
  components: {
    MetadadosContainerLayout,
    InputMoney,
    PlanejamentoAcaoFormApuracaoIntervalo,
    Confirm,
    MetadadosCampoDinamicoData,
  },
  mixins: [
    PlanejamentoContratoFormAcoesApuracaoDefinicao,
    planejamentoContratoCalculoValorTotalAcaoMixin,
  ],
  data() {
    return {
      rules: {
        requiredCondicao: (value) => !!value || this.$t('message.campo_obrigatorio'),
        requiredPeriodoCondicao: (value) => {
          if (this.desabilitaIntervalo) {
            return true;
          }
          return !!value || this.$t('message.campo_obrigatorio');
        },
        valorMinimo: (value, min) => value >= min || this.$t('errors.valor.minimo', { min }),
      },
      dtaInicio: null,
      dtaFim: null,
      desabilitarDataFim: false,
      dtaPeriodoRetroativo: null,
      justificativa: '',
      menuMesPesquisa: false,
      meta: 0.0,
      sigla: '%',
      desabilitarPeriodoPagamento: true,
      desabilitaFormaPagamento: false,
      mesSelecionado: [],
      definicaoPeriodoRetroativo: {
        labelCampo: 'periodo_retroativo',
        nomCampoId: 'periodoRetroativo',
        tipoCampo: 'DATA',
        vlrObrigatorio: true,
      },
      coeficienteCalculoValorTotalAcao: 0,
      menuDataInicio: false,
      menuDataFim: false,
      dataInicioPeriodo: moment().format('YYYY-MM-DD'),
    };
  },
  computed: {
    ...mapGetters('metadados', [
      'getAcaoMetadado',
      'getProdutoMetadado',
    ]),
    valorAcaoTemplateValorTotalAcao() {
      const { configuracao } = this.configuracaoAcao;
      return configuracao.passo3.template === 'VALOR_TOTAL_ACAO';
    },
    valorAcaoCalculadoAutomaticamente() {
      return this.configuracaoAcao.valorAcaoCalculadoAutomaticamente;
    },
    verbaPercentual() {
      return this.passoApuracao.formaBonificacao === 'PERCENTUAL';
    },
    verbaFixa() {
      if (this.passoApuracao.formaBonificacao
        && this.passoApuracao.formaBonificacao === 'FIXO') {
        return true;
      }
      return false;
    },
    metaIntervalo() {
      return !this.desabilitaIntervalo;
    },
    metaAutomatica() {
      return this.configuracaoAcao.calculoAutomatico;
    },
    isAcaoVigenciaTrimestral() {
      return this.configuracaoAcao.indVigenciaTrimestral;
    },
    calculoVolume() {
      return true;
    },
    listaCondicao() {
      if (this.passoApuracao.pagamentoRetroativo === 'NAO') {
        return [
          {
            valor: 'COM_BRUTO',
            nome: this.$tc('condicao.comercial_bruto'),
          },
          {
            valor: 'COM_LIQUID',
            nome: this.$tc('condicao.comercial_liquido'),
          },
          {
            valor: 'LOG_BRUTO',
            nome: this.$tc('condicao.logistico_bruto'),
          },
          {
            valor: 'LOG_LIQUID',
            nome: this.$tc('condicao.logistico_liquido'),
          },
          {
            valor: 'CRESC_BRUT',
            nome: this.$tc('condicao.crescimento_bruto'),
          },
          {
            valor: 'CRESC_LIQU',
            nome: this.$tc('condicao.crescimento_liquido'),
          },
        ];
      }
      return [
        {
          valor: 'COM_BRUTO',
          nome: this.$tc('condicao.comercial_bruto'),
        },
        {
          valor: 'COM_LIQUID',
          nome: this.$tc('condicao.comercial_liquido'),
        },
        {
          valor: 'LOG_BRUTO',
          nome: this.$tc('condicao.logistico_bruto'),
        },
        {
          valor: 'LOG_LIQUID',
          nome: this.$tc('condicao.logistico_liquido'),
        },
      ];
    },
    dataInicioFormatada: {
      get() {
        return formatDateDDMMYYYY(this.passoApuracao.dtaInicio);
      },
      set(newValue) {
        this.passoApuracao.dtaInicio = parseDateYYYYMMDD(newValue);
      },
    },
    dataFimFormatada: {
      get() {
        return formatDateDDMMYYYY(this.passoApuracao.dtaFim);
      },
      set(newValue) {
        this.passoApuracao.dtaFim = parseDateYYYYMMDD(newValue);
      },
    },
    dataInicioPicker: {
      get() {
        return this.passoApuracao.dtaInicio ? this.passoApuracao.dtaInicio : this.dataInicioPeriodo;
      },
      set(newValue) {
        this.passoApuracao.dtaInicio = newValue;
        if (this.isAfter(this.passoApuracao.dtaInicio, this.passoApuracao.dtaFim)) {
          this.passoApuracao.dtaFim = '';
        }
        if (this.passoApuracao.pagamentoRetroativo === 'SIM') {
          this.passoApuracao.dtaFim = this.passoApuracao.dtaInicio;
        }
        this.$emit('PlanejamentoContratoFormAcoesApuracao__fecharContaCorrente');
      },
    },
    dataFimPicker: {
      get() {
        return this.passoApuracao.dtaFim ? this.passoApuracao.dtaFim : this.passoApuracao.dtaInicio;
      },
      set(newValue) {
        this.passoApuracao.dtaFim = newValue;
        if (this.passoApuracao.pagamentoRetroativo === 'SIM') {
          this.passoApuracao.dtaFim = this.passoApuracao.dtaInicio;
        }
        this.$emit('PlanejamentoContratoFormAcoesApuracao__fecharContaCorrente');
      },
    },
  },
  methods: {
    required(value) {
      if (value instanceof Array && value.length === 0 && !this.desabilitarPeriodoPagamento) {
        return this.$t('message.campo_obrigatorio');
      }
      return !!value || this.$t('message.campo_obrigatorio');
    },
    alterarCoeficienteCalculoValorTotalAcao(valor) {
      if (valor) {
        this.passoApuracao.valorAcao = valor;
        this.$emit('PlanejamentoContratoFormAcoesApuracao__fecharContaCorrente');
      }
    },
    condicaoAlterada(condicao) {
      if (condicao != null && (['CRESC_BRUT', 'CRESC_LIQU'].includes(condicao))) {
        this.desabilitaIntervalo = false;
      } else {
        this.passoApuracao.metas = [];
        this.passoApuracao.periodoComparacao = null;
        this.desabilitaIntervalo = true;
      }
      if (condicao && condicao.includes('LIQU')) {
        this.passoApuracao.baseCalculo = 'VALOR_LIQUIDO';
      } else if (condicao && condicao.includes('BRUT')) {
        this.passoApuracao.baseCalculo = 'VALOR_BRUTO';
      }
    },
    alteraValor(valor) {
      if (valor) {
        this.passoApuracao.valorAcao = valor;
      }
      this.$emit('PlanejamentoContratoFormAcoesApuracao__fecharContaCorrente');
    },
    getObjetoFormatado() {
      const {
        pagamentoRetroativo,
        periodoRetroativo,
        formaBonificacao,
        tipoPagamento,
        justificativa,
        dtaInicio,
        dtaFim,
        periodoApuracao,
        condicao,
        baseCalculo,
        periodoComparacao,
        valorAcao,
        metas,
      } = this.passoApuracao;

      const objetoFormatado = {
        pagamentoRetroativo,
        periodoRetroativo,
        formaBonificacao,
        tipoPagamento,
        justificativa,
        dtaInicio,
        dtaFim,
        periodoApuracao,
        condicao,
        baseCalculo,
        periodoComparacao,
        valorAcao,
        calculoBonificacao: 'SELLIN',
        calculoApuracao: formaBonificacao === 'FIXO' ? 'SELLIN_VALOR' : null,
        meta: metas.length ? 0 : null,
        metas,
      };

      this.formataVigencia(objetoFormatado);
      return objetoFormatado;
    },
    formataVigencia(objetoFormatado) {
      objetoFormatado.dtaInicio = this.passoApuracao.dtaInicio;
      objetoFormatado.dtaFim = this.passoApuracao.dtaFim;
    },
    validarMetas() {
      if (this.metaIntervalo && !this.passoApuracao.metas.length) {
        this.$toast(this.$t('message.intervalo_meta_obrigatorio'));
        return false;
      }
      return true;
    },
    isAfter(data, outra) {
      return moment(data, this.formatoSalvar).isAfter(moment(outra, this.formatoSalvar));
    },
    validarDatas() {
      const dataInicial = moment(this.passoApuracao.dtaInicio);
      const dataFinal = moment(this.passoApuracao.dtaFim);
      const dtaVigenciaInicioContrato = moment(this.objetoPassoGeral.dtaVigenciaInicio);
      const dtaVigenciaFimContrato = moment(this.objetoPassoGeral.dtaVigenciaFim);

      if (dataFinal.isBefore(dataInicial)) {
        this.$toast(this.$t('message.data_inicial_maior_final'));
        return false;
      }

      if (dataInicial.isBefore(dtaVigenciaInicioContrato)) {
        this.$toast(this.$t('message.data_inicial_menor_contrato'));
        return false;
      }

      if (dataFinal.isAfter(dtaVigenciaFimContrato)) {
        this.$toast(this.$t('message.data_final_maior_contrato'));
        return false;
      }

      return true;
    },
    pagamentoRetroativoSelecionado(pagRetroativo) {
      this.passoApuracao.periodoRetroativo = [];
      this.desabilitarPeriodoPagamento = pagRetroativo !== 'SIM';
      this.passoApuracao.formaBonificacao = pagRetroativo === 'SIM' ? 'FIXO' : this.passoApuracao.formaBonificacao;
      this.desabilitaFormaPagamento = pagRetroativo === 'SIM';
      if (pagRetroativo === 'SIM') {
        this.desabilitarDataFim = true;
        this.passoApuracao.dtaFim = this.passoApuracao.dtaInicio;
        this.ehFixo = true;
        this.$emit('PlanejamentoContratoFormAcoesApuracao__fecharContaCorrente');
      } else {
        this.desabilitarDataFim = false;
        this.passoApuracao.dtaFim = null;
        this.$emit('PlanejamentoContratoFormAcoesApuracao__fecharContaCorrente');
      }
    },
    validaTipoPagamento(tpPagamento) {
      if (tpPagamento && tpPagamento === 'FIXO') {
        this.ehFixo = true;
        this.passoApuracao.periodoComparacao = null;
      } else {
        this.ehFixo = false;
      }
      this.$emit('PlanejamentoContratoFormAcoesApuracao__fecharContaCorrente');
    },
    validarCamposPreencher(pagRetroativo, formaBonificacao, condicao) {
      if (pagRetroativo === 'SIM') {
        this.desabilitarDataFim = true;
        this.ehFixo = true;
        this.desabilitaFormaPagamento = true;
        this.desabilitarPeriodoPagamento = false;
      }
      if (formaBonificacao === 'PERCENTUAL') {
        this.ehFixo = false;
      }
      if ((['CRESC_BRUT', 'CRESC_LIQU'].includes(condicao))) {
        this.desabilitaIntervalo = false;
      }
    },
    preencherAcaoNova() {
      this.passoApuracao.pagamentoRetroativo = 'NAO';
      this.passoApuracao.periodoApuracao = 'MENSAL';
      this.passoApuracao.meta = this.passoApuracao.meta || 0.00;
    },
    preencherEdicaoAcao() {
      const {
        pagamentoRetroativo,
        formaBonificacao,
        condicao,
      } = this.passoApuracao;
      this.validarCamposPreencher(pagamentoRetroativo, formaBonificacao, condicao);
      this.meta = this.passoApuracao.meta;
      this.passoApuracao.periodoApuracao = 'MENSAL';
    },
  },
  mounted() {
    if (this.novo && !this.copiaAcao) {
      this.preencherAcaoNova();
    } else {
      this.preencherEdicaoAcao();
    }
  },
};
</script>
