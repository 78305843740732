export default {
  name: 'planejamentoContratoCalculoValorTotalAcaoMixin',
  data() {
    return {
    };
  },
  computed: {
    possuiPeriodoApuracaoUnicaVez() {
      return this.passoApuracao
        && this.passoApuracao.periodosApuracao
        && this.passoApuracao.periodosApuracao
          .indexOf('UNICA_VEZ') > -1;
    },
    possuiPeriodoApuracaoMensal() {
      return this.passoApuracao
        && this.passoApuracao.periodosApuracao
        && this.passoApuracao.periodosApuracao
          .indexOf('MENSAL') > -1;
    },
    possuiPeriodoApuracaoTrimestral() {
      return this.passoApuracao
        && this.passoApuracao.periodosApuracao
        && this.passoApuracao.periodosApuracao
          .indexOf('TRIMESTRAL') > -1;
    },
    possuiPeriodoApuracaoAnual() {
      return this.passoApuracao
        && this.passoApuracao.periodosApuracao
        && this.passoApuracao.periodosApuracao
          .indexOf('ANUAL') > -1;
    },
  },
  methods: {
    coeficienteCalculoAlterado(valor) {
      this.coeficienteCalculoValorTotalAcao = valor;
      this.calcularValorTotal();
    },
  },
};
