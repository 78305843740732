import moment from 'moment';
import {
  copyObject,
} from '../../produto/common/functions/helpers';

export default class ContratoConfiguracao {
  constructor(configuracao) {
    this.configuracao = configuracao;
  }

  get objeto() {
    return this.configuracao;
  }

  get idConfiguracao() {
    return this.configuracao.id;
  }

  get nome() {
    const { passo1 } = this.configuracao;
    return passo1.nome;
  }

  get tipoVigencia() {
    const { passo3 } = this.configuracao;
    return passo3.tipoVigencia || '';
  }

  get relacaoCampos() {
    const { passo5 } = this.configuracao;
    const campos = passo5.lista.filter((campo) => !campo.excecao)
      .map((campo) => campo.label)
      .filter((l) => !this.extensoesCarteiraLabel.includes(l));
    return campos;
  }

  get habilitaClientePagador() {
    const { passo2 } = this.configuracao;
    const { listaCliente } = passo2;
    const configPagador = listaCliente
      .filter((cfg) => cfg.label === 'cliente_pagador')[0];
    return configPagador ? configPagador.habilitado : false;
  }

  get habilitaFornecedorPagador() {
    const { passo2 } = this.configuracao;
    const { listaFornecedor } = passo2;
    const configPagador = listaFornecedor
      .filter((cfg) => cfg.label === 'fornecedor_pagador')[0];
    return configPagador ? configPagador.habilitado : false;
  }

  get fornecedorPagadorObrigatorio() {
    const { passo2 } = this.configuracao;
    const { listaFornecedor } = passo2;
    const configPagador = listaFornecedor
      .filter((cfg) => cfg.label === 'fornecedor_pagador')[0];
    return configPagador ? configPagador.obrigatorio : false;
  }

  get extensoesCarteira() {
    const { passo2 } = this.configuracao;
    return (passo2.listaCarteiraCliente || []).filter((c) => c.mnemonico !== 'UNIDADE_NEGOCIO' && c.habilitado);
  }

  get extensoesCarteiraLabel() {
    return this.extensoesCarteira.map((e) => e.label);
  }

  get temExtensaoCarteira() {
    return !!this.extensoesCarteira.length;
  }

  get focoContrato() {
    const { passo2 } = this.configuracao;
    const { listaCliente } = passo2;

    const habilitados = [];
    if ((listaCliente.find((item) => item.label === 'cliente') || {}).habilitado) {
      habilitados.push({
        valor: 'CLIENTE',
        i18n: 'label.foco_cliente',
        indiceRecursao: null,
      });
    }
    if ((listaCliente.find((item) => item.label === 'holding') || {}).habilitado) {
      habilitados.push({
        valor: 'HOLDING',
        i18n: 'label.holding',
        indiceRecursao: null,
      });
    }
    const campoCliente = (listaCliente.find((item) => item.label === 'campo_cliente') || {});
    if (campoCliente.habilitado) {
      habilitados.push({
        valor: campoCliente.campoCliente.mnemonico,
        texto: campoCliente.campoCliente.descricao,
        i18n: `label.${campoCliente.campoCliente.label}`,
        indiceRecursao: campoCliente.campoCliente.indiceRecursao,
      });
    }
    return habilitados;
  }

  get origemContrato() {
    const { passo2 } = this.configuracao;
    const { listaFornecedor } = passo2;

    const habilitados = [];
    listaFornecedor.forEach((f) => {
      if (f.habilitado) {
        habilitados.push(f.label.toUpperCase());
      }
    });
    return habilitados;
  }

  get tiposAcao() {
    const { passo1 } = this.configuracao;
    return passo1.tiposAcao;
  }

  get indDescricaoAutomatica() {
    const { passo2 } = this.configuracao;
    return passo2.indDescricaoAutomatica;
  }

  get descricaoAutomatica() {
    const { passo1 } = this.configuracao;
    return `${passo1.nome} - ${moment().format('DD/MM/YYYY')}`;
  }

  get indPermiteDuplicidadeAcao() {
    const { passo1 } = this.configuracao;
    return passo1.indPermiteDuplicidadeAcao;
  }

  aplicaParametrizacao(metadadosContrato) {
    const copiaMetadados = copyObject(metadadosContrato);
    this.parametrizaExtensoes(copiaMetadados);
    this.parametrizaCamposDinamicos(copiaMetadados);
    return copiaMetadados;
  }

  /**
   * [Parametriza Campos Opcionais]
   *
   * Cruza as informacoes da configuracao com a relacao
   * de campos opcionais, aplicando
   * especificos comprotamentos (ex: obrigatoriedade) da
   * configuracao - feita pelo usuário - ao campo original;
   *
   * @param {*} relacaoCampos
   * @param {*} contexto
   */
  parametrizaCamposOpcionais(relacaoCampos) {
    const { passo2 } = this.configuracao;
    const mapaConfiguracao = {};
    passo2.listaProduto
      .filter((campo) => ['produto'].indexOf(campo.label) >= 0)
      .forEach((campo) => {
        mapaConfiguracao[campo.label] = campo;
      });

    relacaoCampos
      .filter((campo) => mapaConfiguracao[campo.labelCampo] != null)
      .forEach((campo) => {
        const campoConfiguracao = mapaConfiguracao[campo.labelCampo];
        campo.vlrObrigatorio = campoConfiguracao.obrigatorio;
      });
  }

  /**
   * [Parametriza Campos Dinamicos]
   *
   * Cruza as informacoes da configuracao com os metadados,
   * aplicando especificos comportamentos (ex: obrigatoriedade)
   * da configuracao - feita pelo usuário - ao campo original;
   *
   * @param {*} metadados
   */
  parametrizaCamposDinamicos(metadados) {
    const { mapaCamposDinamicos } = metadados;
    if (!mapaCamposDinamicos
      || Object.keys(mapaCamposDinamicos).length === 0) return;

    const { listaDinamica } = this.configuracao.passo2;
    const mapaConfiguracao = {};
    listaDinamica
      .forEach((campo) => {
        mapaConfiguracao[campo.label] = campo;
      });

    const camposAmostragem = Object.keys(mapaCamposDinamicos);
    camposAmostragem
      .filter((chave) => mapaConfiguracao[chave] != null)
      .forEach((chave) => {
        const campoDinamico = mapaCamposDinamicos[chave], // eslint-disable-line
          campoConfiguracao = mapaConfiguracao[chave]; // eslint-disable-line

        // aplica modificacoes
        campoDinamico.vlrObrigatorio = campoConfiguracao.obrigatorio;
      });
  }

  /**
   * [Parametriza Extensoes]
   *
   * Cruza as informacoes da configuracao com os metadados,
   * aplicando especificos comportamentos (ex: obrigatoriedade)
   * da configuracao - feita pelo usuário - à extensao original;
   *
   * @param {*} metadados
   */
  parametrizaExtensoes(metadados) {
    const { mapaEntidades } = metadados;
    if (!mapaEntidades
      || Object.keys(mapaEntidades).length === 0) return;

    const { passo2 } = this.configuracao;
    const mapaConfiguracao = {};
    passo2.listaProduto
      .filter((campo) => ['produto'].indexOf(campo.label) < 0)
      .forEach((campo) => {
        mapaConfiguracao[campo.label] = campo;
      });

    const extensoesAmostragem = Object.keys(mapaEntidades);
    extensoesAmostragem
      .filter((chave) => mapaConfiguracao[chave] != null)
      .forEach((chave) => {
        const extensao = mapaEntidades[chave], // eslint-disable-line
          campoConfiguracao = mapaConfiguracao[chave]; // eslint-disable-line

        // aplica modificacoes
        extensao.depObrigatoria = campoConfiguracao.obrigatorio;
      });
  }
}
