import { mapGetters } from 'vuex';
import realizaCopiaAcao from './PlanejamentoContratoFormAcoesControleCopiaDeAcao';

export default {
  props: {
    acoesRemovidas: {
      type: Array,
      default: () => [],
    },
    configuracao: {
      type: Object,
      default: () => {},
    },
    contrato: {
      type: Object,
      default: () => {},
    },
    contratoEdicao: {
      type: Object,
      default: () => {},
    },
    tiposAcaoContrato: {
      type: Array,
      default: () => [],
    },
    somenteLeitura: {
      type: Boolean,
      default: false,
    },
    alterarFinalizado: Boolean,
    copia: Boolean,
    edicao: Boolean,
    novo: Boolean,
    podeSolicitarAprovacaoContrato: Boolean,
    temFluxoContrato: Boolean,
  },
  data() {
    return {
      workflowApuracaoAcaoResource: this.$api.workflowApuracaoAcao(this.$resource),
      workflowAcaoResource: this.$api.workflowAcao(this.$resource),
      planejamentoAcaoListagemResource: this.$api.planejamentoAcaoListagem(this.$resource),
      planejamentoContratoResource: this.$api.planejamentoContratoSuzano(this.$resource),
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      msgRemocaoAcao: this.$t('message.remover_acao'),
      msgExecutarAnalise: this.$t('message.deseja_executar_analise_acao'),
      mensagemCancelar: this.$tc('message.deseja_cancelar_acao', 1),
      msgFluxoEncerrar: this.$tc('message.deseja_encerrar_acao_contrato', 1),
      msgFluxoEncerrarMassivo: this.$tc('message.deseja_encerrar_acao_massivo', 1),
      mensagemAviso: '',
      msgFluxoSemAprovador: '',
      funcCancelar: () => ({}),
      ehSolicitante: false,
      filtro: '',
      passoCliente: {},
      modalLista: false,
      idAcaoFluxo: null,
      indexAcaoSelecionada: null,
      listAcoesSelecionadas: null,
      acaoSelecionada: null,
      acaoAgrupadoraSelecionada: null,
      apuracoesAgrupadas: [],
      atualizaFiltroMassivo: false,
    };
  },
  computed: {
    ...mapGetters('metadados', [
      'getProdutoMetadado',
    ]),
    ...mapGetters('roles', [
      'getAllRoles',
      'getUsuario',
    ]),
    ...mapGetters('usuario', [
      'usuarioLogado',
    ]),
    ...mapGetters('implantacao', [
      'indModoVarejo',
    ]),
    herdarPeriodoAcao() {
      const { tipoVigencia } = this.configuracao;
      const herdarPeriodo = ['HERDAR_PERIODO_ACAO'].includes(tipoVigencia);

      return herdarPeriodo;
    },
    idContrato() {
      const { id } = this.contratoEdicao;
      return id || null;
    },
    contratoEmCadastro() {
      return this.contratoEdicao && this.contratoEdicao.passoGeral
        && ['EM_CADASTRO', 'APROVADO'].includes(this.contratoEdicao.passoGeral.status);
    },
    contratoEncerrado() {
      return this.contratoEdicao && this.contratoEdicao.passoGeral
        && ['ENCERRADO'].includes(this.contratoEdicao.passoGeral.status);
    },
    permiteCadastrarContrato() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'CONTRATO_CRUD').length;
    },
    divisao() {
      return this.usuarioLogado.divisao ? this.usuarioLogado.divisao.split(',') : [];
    },
  },
  methods: {
    permiteRemoverAcao(acao) {
      return acao && !acao.id;
    },
    permiteEditarAcao(acao) {
      return this.acaoEmCadastro(acao)
        && this.contratoEmCadastro
        && !this.acaoAgrupadora(acao);
    },
    defineIndicesGlobais() {
      this.acoes.forEach((acao, index) => {
        acao.index = index;
      });
    },
    permiteSolicitarAprovacao(acao) {
      return this.acaoEmCadastro(acao)
        && !this.podeSolicitarAprovacaoContrato
        && !!this.contrato.id
        && this.divisaoUsuario(acao);
    },
    permiteEditar(acao) {
      return this.acaoEmCadastro(acao)
        && !this.acaoAgrupadora(acao)
        && this.divisaoUsuario(acao);
    },
    divisaoUsuario(acao) {
      return acao
            && acao.passoGeral
            && acao.passoGeral.divisao.id
            && this.divisao.includes(acao.passoGeral.divisao.id.toString());
    },
    verificarSolicitante(idAcao) {
      this.workflowAcaoResource.solicitante({ idAcao })
        .then((response) => {
          if (response.data.usuarioHabilitado) {
            this.ehSolicitante = true;
          }
        });
    },
    acaoEmCadastro(acao) {
      return (acao && acao.passoGeral
        && acao.passoGeral.status === 'EM_CADASTRO');
    },
    tipoJaUtilizado(acao) {
      const tipoAcao = this.tiposAcaoContrato
        .find((tipo) => tipo.id === acao.idAcaoTipo);
      return tipoAcao && tipoAcao.atingiuMaximo;
    },
    acaoAguardandoAprovacao(acao) {
      return (acao && acao.passoGeral
        && acao.passoGeral.status === 'AGUARDANDO_APROVACAO');
    },
    podeExcluir(acao) {
      return this.acaoEmCadastro(acao);
    },
    acaoAgrupadora(acao) {
      return acao.passoGeral.indAcaoAgrupadora;
    },
    acaoEmAnalise(acao) {
      return (acao && acao.passoGeral
        && acao.passoGeral.status === 'EM_ANALISE');
    },
    podeEncerrar(acao) {
      return acao.passoGeral.status === 'APROVADO';
    },
    isUsuarioCriacao(acao) {
      return (acao && acao.passoGeral
        && acao.passoComportamento.idUsuarioCriacao === this.usuarioLogado.id);
    },
    montarData(acao) {
      if (!this.herdarPeriodoAcao) {
        return '';
      }
      let dtaInicio = this.moment(acao.passoComportamento.dtaInicio)
        .format('DD/MM/YYYY');
      let dtaFim = this.moment(acao.passoComportamento.dtaFim)
        .format('DD/MM/YYYY');

      if (acao.passoComportamento.dtaInicio.length < 8) {
        dtaInicio = this.moment(acao.passoComportamento.dtaInicio, 'YYYY-MM').startOf('month').format('DD/MM/YYYY');
        dtaFim = this.moment(acao.passoComportamento.dtaFim, 'YYYY-MM').endOf('month').format('DD/MM/YYYY');
      }

      return this.$t('label.periodo_apuracao_datas', { dtaInicio, dtaFim });
    },
    formatarData(data) {
      return this.moment(data)
        .format('DD/MM/YYYY');
    },
    filtrar(filtro, executarFiltros) {
      if (filtro === '' && executarFiltros) {
        this.filtro = 'filtro';
        setTimeout(() => { this.filtro = ''; }, 500);
      } else {
        this.filtro = filtro || '';
      }
    },
    copiaAcao(index) {
      const copia = realizaCopiaAcao(
        this.acoes[index],
        this.getProdutoMetadado,
      );
      copia.passoGeral.status = 'EM_CADASTRO';

      this.$emit(
        'PlanejamentoContratoFormAcoesControle__CopiaAcao',
        {
          acao: copia,
          callback: (retorno) => this.criaCardAcao(retorno),
        },
      );
    },
    confirmaRemoverAcao(index) {
      this.indexAcaoSelecionada = index;
      setTimeout(() => this.$refs.removerAcaoDialog.open());
    },
    removeAcao() {
      const index = this.indexAcaoSelecionada;
      const acao = this.acoes[index];
      if (!acao.id) {
        const { id } = acao;
        if (id) {
          this.$emit('PlanejamentoContratoFormAcoesControlesItens__pushAcaoRemovida', acao);
        }

        this.acoes.splice(index, 1);
        this.acoesFormatadas.splice(index, 1);

        this.$emit(
          'PlanejamentoContratoFormAcoesControle__RemoveAcao',
          acao,
        );
      } else {
        const idAcao = acao.id;
        this.planejamentoAcaoResource.cancelarAcao({ idAcao }, { idAcao })
          .then(() => {
            this.acoes.splice(index, 1);
            this.acoesFormatadas.splice(index, 1);
            this.$emit(
              'PlanejamentoContratoFormAcoesControle__RemoveAcao',
              acao,
            );
          })
          .catch((err) => {
            this.$toast(err.data.error);
          });
      }
    },
    confirmarExecutarAnalise(index) {
      this.indexAcaoSelecionada = index;
      setTimeout(() => this.$refs.executarAnaliseDialog.open());
    },
    executarAnalise() {
      const index = this.indexAcaoSelecionada;
      const acao = this.acoes[index];
      const dadosAcao = {
        index,
        idAcao: acao.id,
      };

      this.$emit('PlanejamentoContratoFormAcoesControle__ExecutarAnalise', dadosAcao);
    },
    preencherJustificativa(acao, justificativa) {
      acao.justificativa = justificativa;
    },
    confirmarEncerrarAcao(index) {
      this.indexAcaoSelecionada = index;
      setTimeout(() => this.$refs.encerrarAcaoDialog.open());
    },
    encerrarAcao() {
      const index = this.indexAcaoSelecionada;
      const idApuracao = this.acoes[index].id;
      const observacao = this.$t('message.contrato_acao_massivo.observacao_encerrar_acao');
      this.workflowApuracaoAcaoResource.cancelar({ idApuracao }, { observacao })
        .then(() => {
          this.$toast(this.$t('message.contrato_acao_massivo.acao_encerrada'));
          this.recarregarDados(idApuracao, this.indexAcaoSelecionada);
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    confirmarEncerrarAcaoMassivo(index) {
      this.listAcoesSelecionadas = index;
      setTimeout(() => this.$refs.encerrarAcaoMassivoDialog.open());
    },
    confirmaCancelarAcao(index) {
      this.indexAcaoSelecionada = index;
      this.mensagemCancelar = this.$tc('message.deseja_cancelar_acao', 1);
      this.funcCancelar = this.cancelarAcao;
      setTimeout(() => this.$refs.cancelarAcaoDialog.open());
    },
    confirmaCancelarFluxo(index) {
      this.indexAcaoSelecionada = index;
      this.mensagemCancelar = this.$tc('message.deseja_cancelar_acao', 1);
      this.funcCancelar = this.cancelarAcaoFluxo;
      setTimeout(() => this.$refs.cancelarAcaoDialog.open());
    },
    cancelarAcao(justificativa) {
      const index = this.indexAcaoSelecionada;
      const acao = this.acoes[index];
      const dadosAcao = {
        justificativa,
        index,
        usuarioCancelamento: this.getUsuario,
        idAcao: acao.id,
      };

      this.$emit('PlanejamentoContratoFormAcoesControle__CancelarAcao', dadosAcao);
    },
    cancelarAcaoFluxo(justificativa) {
      const index = this.indexAcaoSelecionada;
      const acao = this.acoes[index];
      const param = {
        observacao: justificativa,
        idAcao: acao.id,
      };
      this.workflowAcaoResource.cancelar(param, param)
        .then(() => {
          this.$toast(this.$t('message.fluxo_cancelado'));
          const dadosAcao = {
            justificativa,
            index,
            usuarioCancelamento: this.getUsuario,
            idAcao: acao.id,
          };
          (this.$refs[`acaoFluxo${index}`][0] || this.$refs[`acaoFluxo${index}`]).preencherJustificativa(justificativa);
          this.preencherJustificativa(this.acoes[index], justificativa);
          this.$emit('PlanejamentoContratoFormAcoesControle__CancelarFluxo', dadosAcao);
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    editaVisualizaAcao(acao, index) {
      this.$emit(
        'PlanejamentoContratoFormAcoesControle__EditaAcao',
        {
          acao,
          index,
          callback: (retorno) => this.atualizaArrayAcoes(retorno, index),
        },
      );
    },
    criaCardAcao(retorno) {
      const { acao, acaoFormatada } = retorno;
      const divisao = {
        id: this.usuarioLogado.divisao.split(',')[0],
      };
      acao.passoGeral.status = 'EM_CADASTRO';
      acao.passoGeral.divisao = divisao;
      acao.passoGeral.temFluxo = false;
      acao.index = this.acoes.length;
      this.acoes.push(acao);

      if (!acao.id) {
        this.registraNovaAcaoFormatada(acaoFormatada);
      }
      setTimeout(() => this.$forceUpdate());
    },
    registraNovaAcaoFormatada(acaoFormatada) {
      const lastIndexOf = this.acoes.length - 1;
      this.acoesFormatadas[lastIndexOf] = acaoFormatada;
    },
    atualizaArrayAcoes(retorno, index) {
      const { acao, acaoFormatada } = retorno;

      this.acoes[index] = acao;
      this.acoesFormatadas[index] = acaoFormatada;
    },
    podeCancelar(acao) {
      return this.acaoEmCadastro(acao)
        && acao.id
        && !!this.getAllRoles.filter((el) => el === 'CONTRATO_CRUD').length
        && this.usuarioLogado.tipo === 'FUNCIONARIO';
    },
    podeCancelarFluxo(acao) {
      return this.acaoAguardandoAprovacao(acao)
        && acao.id
        && !!this.getAllRoles.filter((el) => el === 'CONTRATO_CRUD').length
        && this.usuarioLogado.tipo === 'FUNCIONARIO'
        && !this.temFluxoContrato;
    },
    solicitarAprovacao(acao) {
      if (!acao.passoGeral.idAcaoAgrupadora) {
        this.executaSolicitarAprovacao(acao.index);
      } else {
        this.acaoSelecionada = acao;
        this.$refs.modalApuracoesAgrupadas.validarAcaoAgrupadoraEApuracoes(acao);
      }
    },
    exibirErroConflitoAcaoAgrupadora(nomeAcao) {
      this.mensagemAviso = this.$t('errors.contrato.solicitar_aprovacao_acao_data_conflito_acao_agrupadora',
        { nomeAcaoAgrupadora: nomeAcao });
      this.$refs.mensagensAvisoDialog.open();
    },
    executaSolicitarAprovacao(index = null) {
      if (index === null) {
        index = this.acaoSelecionada.index;
      }
      const acao = this.acoes[index];
      if (!acao.id) {
        this.mensagemAviso = this.$t('message.salvar_contrato_antes_solicitar_aprovacao');
        this.$refs.mensagensAvisoDialog.open();
        return;
      }
      const resource = this.workflowAcaoResource.buscaPassosClientes;

      this.indexAcaoSelecionada = index;
      const dadosAcao = {
        idAcao: acao.id,
      };
      this.idAcaoFluxo = acao.id;

      resource(dadosAcao)
        .then((response) => {
          const passosCliente = response.data;
          if (passosCliente.length === 0) {
            this.iniciaFluxo();
          } else if (passosCliente.length > 1) {
            const msg = this.$t('errors.multiplos_passos_cliente');
            this.$toast(msg);
          } else if (!passosCliente[0].usuarios.length) {
            this.msgFluxoSemAprovador = this.$t('message.nao_tem_cliente_aprovador');
            setTimeout(() => this.$refs.fluxoSemUsuarioClienteDialog.open());
          } else {
            // Implementado suporte para apenas 1 passo cliente/fornecedor
            const [passoCli] = passosCliente;
            this.passoCliente = passoCli;
            this.$refs.modalUsuarioCliente.open();
          }
        }).catch((err) => {
          this.$toast(this.$t(`errors.fluxo.${err.data.error}`));
        });
    },
    selecionaAprovadorPassoClienteFornecedor(idPasso, idUsuario) {
      const idAcao = this.idAcaoFluxo;
      const params = {
        idAcao,
        idPasso,
        idUsuario,
      };
      const resource = this.workflowAcaoResource.associaUsuarioClienteAprovador;
      resource(params, params)
        .then(() => this.iniciaFluxo())
        .catch((err) => this.$error(this, err));
    },
    iniciaFluxo() {
      const idAcao = this.idAcaoFluxo;
      this.atualizaFiltroMassivo = false;
      this.workflowAcaoResource.iniciaFluxo({ idAcao }, { idAcao })
        .then(() => {
          this.recarregarDados(idAcao, this.indexAcaoSelecionada);
          this.$toast(this.$t('message.solicitacao_aprovacao'));
        })
        .catch(() => {
          this.$toast(this.$t('message.contrato_acao_massivo.salvar_acao'));
        });
    },
    recarregarDados(idAcao, index) {
      const executarFiltros = true;
      const filtro = '';
      this.planejamentoAcaoListagemResource.buscarStatusAtualizado({ idAcao })
        .then((res) => {
          const dadosAtualizados = res.data;
          dadosAtualizados.index = index;
          if (dadosAtualizados.statusAcao === 'AGUARDANDO_APROVACAO') {
            (this.$refs[`acaoFluxo${index}`][0] || this.$refs[`acaoFluxo${index}`]).recarregarAprovador(dadosAtualizados.statusAcao);
          }
          if (dadosAtualizados.statusAcao === 'EM_ANALISE') {
            (this.$refs[`acaoFluxo${index}`][0] || this.$refs[`acaoFluxo${index}`]).buscarJustificativa(idAcao);
          }
          this.$emit('PlanejamentoContratoFormAcoesControle__atualizaDadosFluxoAcao', dadosAtualizados);
          if (!this.atualizaFiltroMassivo) {
            this.filtrar(filtro, executarFiltros);
          }
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    solicitarAprovacaoMassivo(acoes) {
      const listIdAcao = this.validaFluxoMassivo(acoes, 'EM_CADASTRO', 'AGUARDANDO_APROVACAO');
      if (listIdAcao.length === 0) {
        this.$toast(this.$t('message.contrato_acao_massivo.nao_existe_acao_solicitar'));
        return;
      }
      Promise.all(listIdAcao.map((c) => {
        const idAcao = c;
        return this.workflowAcaoResource.iniciaFluxo({ idAcao },
          { idAcao });
      })).then(() => {
        this.$toast(this.$t('message.contrato_acao_massivo.acao_solicitada_massiva'));
        listIdAcao.map((c) => {
          const idAcao = c;
          const acaoFiltrada = this.acoes.filter((el) => el.id === idAcao);
          this.atualizaFiltroMassivo = true;
          return this.recarregarDados(idAcao, acaoFiltrada[0].index);
        });
      })
        .catch(() => {
          this.$toast(this.$t('message.contrato_acao_massivo.salvar_acao'));
        });
    },
    aprovacaoMassivo(acoes) {
      const listIdAcao = this.validaFluxoMassivo(acoes, 'AGUARDANDO_APROVACAO', 'APROVADO');
      Promise.all(listIdAcao.map((c) => {
        const idAcao = c;
        return this.workflowAcaoResource.aprovarPasso({ idAcao },
          { observacao: this.$t('message.contrato_acao_massivo.acao_aprovada_massiva_justificativa') });
      })).then(() => {
        this.$toast(this.$t('message.contrato_acao_massivo.acao_aprovada_massiva'));
        listIdAcao.map((c) => {
          const idAcao = c;
          const acaoFiltrada = this.acoes.filter((el) => el.id === idAcao);
          this.atualizaFiltroMassivo = true;
          return this.recarregarDados(idAcao, acaoFiltrada[0].index);
        });
      })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    enviarAnaliseMassivo(acoes) {
      const listIdAcao = this.validaFluxoMassivo(acoes, 'AGUARDANDO_APROVACAO', 'EM_ANALISE');
      if (listIdAcao.length === 0) {
        this.$toast(this.$t('message.contrato_acao_massivo.nao_existe_acao_solicitar_analise'));
        return;
      }
      Promise.all(listIdAcao.map((c) => {
        const idAcao = c;
        return this.workflowAcaoResource.enviarAnalise({ idAcao },
          { observacao: this.$t('message.contrato_acao_massivo.acao_solicita_analise_massiva_justificativa') });
      })).then(() => {
        this.$toast(this.$t('message.contrato_acao_massivo.acao_solicita_analise_massiva'));
        listIdAcao.map((c) => {
          const idAcao = c;
          const acaoFiltrada = this.acoes.filter((el) => el.id === idAcao);
          this.atualizaFiltroMassivo = true;
          return this.recarregarDados(idAcao, acaoFiltrada[0].index);
        });
      })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    reprovarMassivo(acoes) {
      const listIdAcao = this.validaFluxoMassivo(acoes, 'AGUARDANDO_APROVACAO', 'REPROVADO');
      if (listIdAcao.length === 0) {
        this.$toast(this.$t('message.contrato_acao_massivo.nao_existe_acao_reprovar'));
        return;
      }
      Promise.all(listIdAcao.map((c) => {
        const idAcao = c;
        return this.workflowAcaoResource.reprovar({ idAcao },
          { observacao: this.$t('message.contrato_acao_massivo.acao_reprovada_massiva_justificativa') });
      })).then(() => {
        this.$toast(this.$t('message.contrato_acao_massivo.acao_reprovada_massiva'));
        listIdAcao.map((c) => {
          const idAcao = c;
          const acaoFiltrada = this.acoes.filter((el) => el.id === idAcao);
          this.atualizaFiltroMassivo = true;
          return this.recarregarDados(idAcao, acaoFiltrada[0].index);
        });
      })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    encerrarMassivo() {
      const listIdAcao = this.validaFluxoMassivo(this.listAcoesSelecionadas, 'APROVADO');
      if (listIdAcao.length === 0) {
        this.$toast(this.$t('message.contrato_acao_massivo.nao_existe_acao_encerrar'));
        return;
      }
      Promise.all(listIdAcao.map((c) => {
        const idApuracao = c;
        return this.workflowApuracaoAcaoResource.cancelar({ idApuracao },
          { observacao: this.$t('message.contrato_acao_massivo.observacao_encerrar_acao') });
      })).then(() => {
        this.$toast(this.$t('message.contrato_acao_massivo.acao_encerrada'));
        listIdAcao.map((c) => {
          const idAcao = c;
          const acaoFiltrada = this.acoes.filter((el) => el.id === idAcao);
          this.atualizaFiltroMassivo = true;
          return this.recarregarDados(idAcao, acaoFiltrada[0].index);
        });
      })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    validaFluxoMassivo(acoes, statusValido, statusDiferente) {
      const listIdAcao = [];
      acoes.forEach((el) => {
        this.acoes.filter((ac) => ac.passoGeral.status === statusValido
            && ac.passoGeral.status !== statusDiferente
            && ac.index === el.index)
          .forEach((ac) => {
            listIdAcao.push(ac.id);
          });
      });
      return listIdAcao;
    },
  },
};
