export default {
  computed: {
  },
  data() {
    return {
      produtoResource: this.$api.produto(this.$resource),
      tipoBeneficioResources: this.$api.tipoBeneficio(this.$resource),

      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        requiredAny: (val) => {
          const msg = this.$t('message.campo_obrigatorio');
          return (val && val.length && val.length > 0) || msg;
        },
      },
      listaPeriodosApuracao: [
        {
          value: 'MENSAL',
          nome: this.$tc('label.mensal'),
        },
      ],

      pagamentosRetroativo: [
        {
          nome: this.$tc('label.sim'),
          value: 'SIM',
        },
        {
          nome: this.$tc('label.nao'),
          value: 'NAO',
        },
      ],

      listaFormaPagamento: [
        {
          nome: `${this.$tc('label.fixo', 1)}`,
          value: 'FIXO',
        },
        {
          nome: `${this.$tc('label.percentual', 1)}`,
          value: 'PERCENTUAL',
        },
      ],

      listaPagamentoPermitidos: [
        {
          valor: 'DESCONTO_NOTA',
          nome: this.$tc('label.desconto_nota'),
        },
        {
          valor: 'DEPOSITO_CONTA',
          nome: this.$tc('label.deposito_conta'),
        },
      ],

      listaBaseCalculo: [
        {
          valor: 'VALOR_LIQUIDO',
          nome: this.$tc('label.valor_liquido'),
        },
        {
          valor: 'VALOR_BRUTO',
          nome: this.$tc('label.valor_bruto'),
        },
      ],
      listaPeriodosComparacao: [
        {
          valor: 'MES',
          nome: this.$tc('label.mes'),
        },
        {
          valor: 'TRIMESTRE',
          nome: this.$tc('label.trimestre'),
        },
        {
          valor: 'SEMESTRE',
          nome: this.$tc('label.semestre'),
        },
        {
          valor: 'ANO',
          nome: this.$tc('label.ano'),
        },
      ],

      sigla: '',
      metas: [],
      descontos: [],
      tiposPagamento: [],
      desabilitaIntervalo: true,
      ehFixo: true,
      dialog: {
        message: '',
        agree: () => {},
        disagree: () => {},
      },

      destacarMeta: false,

      formaBonificacaoAnterior: null,

      parametrosAplicados: false,
      metadadosParametrizado: null,
      camposFormulario: {
        padrao: [
          {
            labelCampo: 'produto',
            nomCampoId: 'idProduto',
            tipoCampo: 'MULTIPLA_ESCOLHA',
            async: {
              fetchFunction: (autocomplete) => {
                const parametros = {
                  autocomplete,
                  ...this.$refs.containerExcecao.getValoresDependencias(),
                };
                return this.produtoResource.selecao(parametros);
              },
              itemValue: 'id',
              itemText: 'nomProduto',
            },
          },
        ],
      },
      layoutClass: { wrap: true },
      inputLayout: {
        xs12: true,
        sm12: true,
        md6: true,
      },
    };
  },
  methods: {
    buscarTiposPagamento() {
      return this.tipoBeneficioResources
        .listarAtivos()
        .then((response) => {
          this.tiposPagamento = response.data;
        });
    },
  },
};
