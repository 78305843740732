<template>
  <v-list class="py-0">
    <v-list-item @click="abrirAcompanhamento"
                 v-if="mostrarAcompanhamento">
      <v-list-item-action>
        <v-icon>assignment_turned_in</v-icon>
      </v-list-item-action>
      <v-list-item-title>{{ $t('label.acompanhamento') }}</v-list-item-title>
    </v-list-item>

    <acao-fluxo
      v-if="mostrarJustificativa"
      ref="acaoMostrarJustificativa"
      icone="info"
      :mostrarRodape="false"
      :layoutGrid="true"
      @click.native="preencheInformacoesJustificativa"
      :texto-acao="$tc('label.justificativa', 1)"
      @AcaoFluxo_menuAberto="preencheInformacoesJustificativa">
    </acao-fluxo>

    <acompanhamento
      ref="modalAcompanhamento"
      :fluxo="statusPassos"
    />
  </v-list>
</template>

<script>
import PlanejamentoAcaoFluxoFuncoes from '../../produto/spa/planejamento-acao/PlanejamentoAcaoFluxoFuncoes';

export default {
  name: 'PlanejamentoAcaoFluxoList',
  mixins: [PlanejamentoAcaoFluxoFuncoes],
};
</script>
