var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{key:_vm.atualizaBotaoAprovar,staticClass:"mx-0",attrs:{"align":"center"}},[_c('div',{staticStyle:{"padding":"8px"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.divisaoContrato())?_c('v-btn',_vm._g({staticClass:"ma-0",attrs:{"fab":"","dark":false,"color":"primary","small":"","disabled":false},on:{"click":function($event){return _vm.solicitarAprovacaoMassivo(_vm.selected)}}},on),[_c('v-icon',[_vm._v("send")])],1):_vm._e()]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('label.solicitar_aprovacao')))])])],1),_c('div',{staticStyle:{"padding":"8px"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.usuarioAprovador)?_c('v-btn',_vm._g({attrs:{"fab":"","dark":false,"color":"primary","small":"","disabled":false},on:{"click":function($event){return _vm.aprovacaoMassivo(_vm.selected)}}},on),[_c('v-icon',[_vm._v("thumb_up")])],1):_vm._e()]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('label.aprovar')))])])],1),_c('div',{staticStyle:{"padding":"8px"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.usuarioAprovador)?_c('v-btn',_vm._g({staticClass:"ma-0",attrs:{"fab":"","dark":false,"color":"primary","small":"","disabled":false},on:{"click":function($event){return _vm.enviarAnaliseMassivo(_vm.selected)}}},on),[_c('v-icon',[_vm._v("announcement")])],1):_vm._e()]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('label.enviar_analise')))])])],1),_c('div',{staticStyle:{"padding":"8px"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.usuarioAprovador)?_c('v-btn',_vm._g({staticClass:"ma-0",attrs:{"fab":"","dark":false,"color":"primary","small":"","disabled":false},on:{"click":function($event){return _vm.reprovarMassivo(_vm.selected)}}},on),[_c('v-icon',[_vm._v("thumb_down")])],1):_vm._e()]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('label.reprovar')))])])],1),_c('div',{staticStyle:{"padding":"8px"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.divisaoContrato())?_c('v-btn',_vm._g({staticClass:"ma-0",attrs:{"fab":"","dark":false,"color":"primary","small":"","disabled":false},on:{"click":function($event){return _vm.confirmarEncerrarAcaoMassivo(_vm.selected)}}},on),[_c('v-icon',[_vm._v("event_busy")])],1):_vm._e()]}}])},[_c('span',[_vm._v(_vm._s(_vm.$tc('label.encerrar_acao', 2)))])])],1)]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.cabecalhoPorTipoAcao(),"items":_vm.copiaAcoes,"show-select":"","item-key":"index","search":_vm.filtro,"no-data-text":_vm.$t('label.tabela_sem_conteudo'),"footer-props":{
      itemsPerPageOptions: [5, 10, 20],
    }},scopedSlots:_vm._u([{key:"item.acoes",fn:function(ref){
    var item = ref.item;
    var isSelected = ref.isSelected;
return [_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false,"eager":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":""}},on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true),model:{value:(item.modalLista),callback:function ($$v) {_vm.$set(item, "modalLista", $$v)},expression:"item.modalLista"}},[_c('v-list',{staticClass:"py-0"},[(_vm.divisaoUsuario(_vm.acoes[item.index]) && !_vm.contratoEncerrado)?_c('v-list-item',{on:{"click":function($event){return _vm.copiaAcao(item.index)}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("library_add")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$tc('label.duplicar_acao', 1)))])],1):_vm._e(),(_vm.podeExcluir(_vm.acoes[item.index]) && _vm.divisaoUsuario(_vm.acoes[item.index]) && _vm.permiteCadastrarContrato)?_c('v-list-item',{on:{"click":function($event){return _vm.confirmaRemoverAcao(item.index)}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("remove_circle")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$tc('label.excluir_a_acao', 1)))])],1):_vm._e(),_c('v-list-item',{on:{"click":function($event){return _vm.editaVisualizaAcao(_vm.acoes[item.index], item.index)}}},[_c('v-list-item-action',[(_vm.permiteEditar(_vm.acoes[item.index]) && _vm.permiteCadastrarContrato)?_c('v-icon',[_vm._v("edit")]):_c('v-icon',[_vm._v("visibility")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.permiteEditar(_vm.acoes[item.index]) && _vm.permiteCadastrarContrato ? _vm.$t('label.editar_a_acao') : _vm.$tc('label.visualizar_acao', 2)))])],1),(_vm.divisaoUsuario(_vm.acoes[item.index]) && _vm.podeCancelarFluxo(_vm.acoes[item.index]) && _vm.permiteCadastrarContrato)?_c('v-list-item',{on:{"click":function($event){return _vm.confirmaCancelarFluxo(item.index)}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("clear")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$tc('label.cancelar', 1)))])],1):_vm._e(),(_vm.acaoEmAnalise(_vm.acoes[item.index])
             && _vm.permiteCadastrarContrato
             && !_vm.temFluxoContrato
             && _vm.divisaoUsuario(_vm.acoes[item.index]))?_c('v-list-item',{on:{"click":function($event){return _vm.confirmarExecutarAnalise(item.index)}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("edit")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$tc('label.executar_analise', 1)))])],1):_vm._e()],1),(_vm.acoes[item.index])?_c('planejamento-acao-fluxo-list',{key:_vm.acoes[item.index].id,ref:("acaoFluxo" + (item.index)),attrs:{"idAcao":_vm.acoes[item.index].id,"acao-de-contrato":true,"status":_vm.acoes[item.index].passoGeral.status,"exibir-justificativa":false,"exibir-justificativa-em-cadastro":_vm.acoes[item.index].passoGeral.temFluxo,"exibir-acompanhamento":_vm.acoes[item.index].passoGeral.temFluxo},on:{"recarregar":function($event){return _vm.recarregarDados(_vm.acoes[item.index].id, item.index)},"PlanejamentoAcaoFluxoFuncoes__justificativa":function (j) { return _vm.preencherJustificativa(_vm.acoes[item.index], j); }}}):_vm._e()],1)]}},{key:"item.dtaInicio",fn:function(ref){
             var item = ref.item;
return [_vm._v(" "+_vm._s(item.dtaInicio)+" ")]}},{key:"item.dtaFim",fn:function(ref){
             var item = ref.item;
return [_vm._v(" "+_vm._s(item.dtaFim)+" ")]}},{key:"item.status",fn:function(ref){
             var item = ref.item;
return [_c('status',{attrs:{"status":item.status || 'EM_CADASTRO',"tooltip":item.status || 'EM_CADASTRO'}})]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('confirm',{ref:"mensagensAvisoDialog",attrs:{"message":_vm.mensagemAviso,"persistent":true,"hide-disagree":"","agree-label":_vm.$t('label.ok')}}),_c('confirm',{ref:"removerAcaoDialog",attrs:{"message":_vm.msgRemocaoAcao,"persistent":true},on:{"agree":_vm.removeAcao}}),_c('confirm',{ref:"executarAnaliseDialog",attrs:{"message":_vm.msgExecutarAnalise,"persistent":true},on:{"agree":_vm.executarAnalise}}),_c('confirm',{ref:"fluxoSemUsuarioClienteDialog",attrs:{"message":_vm.msgFluxoSemAprovador,"persistent":true},on:{"agree":_vm.iniciaFluxo}}),_c('confirm',{ref:"encerrarAcaoMassivoDialog",attrs:{"message":_vm.msgFluxoEncerrarMassivo,"persistent":true},on:{"agree":_vm.encerrarMassivo}}),_c('planejamento-acao-form-usuario-cliente',{ref:"modalUsuarioCliente",attrs:{"title":_vm.indModoVarejo ? _vm.$tc('title.selecao_aprovador_fornecedor', 1) : _vm.$tc('title.selecao_aprovador_cliente', 1),"passoCliente":_vm.passoCliente},on:{"selecionarUsuarioCliente":_vm.selecionaAprovadorPassoClienteFornecedor}}),_c('PlanejamentoContratoDialogCancelarAcao',{ref:"cancelarAcaoDialog",attrs:{"cancelarAcao":_vm.funcCancelar,"mensagem":_vm.mensagemCancelar}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }