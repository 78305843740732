<template>
  <v-container fluid grid-list-md class="px-0 pb-0">
    <v-row>
      <v-col cols="12">
        <div class="title-float accent--text">
          {{$tc('label.campos_gerais', 1)}}
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-container fluid grid-list-md>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  id="descricao_campos_gerais"
                  v-model="passoGeral.descricao"
                  :rules="[rules.required]"
                  :disabled="somenteLeitura"
                  :label="`${$tc('label.descricao', 1)} *`"/>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    alterarFinalizado: Boolean,
    copia: Boolean,
    novo: Boolean,
    passoGeral: Object,
    edicao: Boolean,
    somenteLeitura: Boolean,
    configuracaoAcao: Object,
    justificativa: String,
    camposLimitadores: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters('metadados', [
      'getAcaoMetadado',
      'getProdutoMetadado',
    ]),
  },
  data() {
    return {
      produtoResource: this.$api.produto(this.$resource),

      layoutClass: { wrap: true },
      inputLayout: {
        xs12: true,
        sm6: true,
        md3: true,
      },

      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
    };
  },
  methods: {
    getObjetoFormatado() {
      const { idConfiguracao, idAcaoTipo } = this.configuracaoAcao;
      const {
        id,
        descricao,
        status,
      } = this.passoGeral;

      const objetoFormatado = {
        id,
        idAcaoTipo,
        idConfiguracao,
        descricao,
        status,
      };

      return objetoFormatado;
    },
  },
  mounted() {
  },
};
</script>
