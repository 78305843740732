<template>
  <v-card>
    <v-card-title>
      <slot name="titulo-acao"></slot>
      <v-spacer></v-spacer>
      <v-icon @click="toggleResumo"
        v-show="resumoAberto">
        keyboard_arrow_up
      </v-icon>
      <v-icon @click="toggleResumo"
        v-show="!resumoAberto">
        keyboard_arrow_down
      </v-icon>
    </v-card-title>

    <v-container fluid grid-list-md class="pt-0 pl-3" v-show="resumoAberto">
      <v-row>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="valor"
            :disabled="true"
            :label="`${$tc('label.valor', 1)} *`"/>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="intervalo"
            :disabled="true"
            :label="`${$tc('label.intervalo_data', 1)} *`"/>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="tipoVerba"
            :disabled="true"
            :label="`${$tc('label.tipo_verba', 1)} *`"/>
        </v-col>
        <v-col cols="12" md="3" class="d-flex align-center">
            <v-btn
              v-if="habilitaBuscar"
              @click="buscarPeriodos()"
              color="primary">
              {{ $t('label.buscar') }}
            </v-btn>
          </v-col>
      </v-row>
      <v-card-text>
          <v-data-table
            :headers="headers"
            :items="contasCorrentes"
            :options.sync="pagination"
            :server-items-length="totalPage"
            :no-data-text="$t('label.tabela_sem_conteudo')"
            :footer-props="{
              itemsPerPageOptions: [5, 10, 20, 30],
            }">
            <template v-slot:item.saldo="{ item }">
               {{ passoApuracao.formaBonificacao === 'FIXO' ? getMoney(item.saldo) : getPercent(item.saldo) }}
            </template>
            <template v-slot:item.saldoAjustado="{ item }">
              <div :style="item.styleText">
               {{ passoApuracao.formaBonificacao === 'FIXO' ? getMoney(item.saldoAjustado) : getPercent(item.saldoAjustado) }}
              </div>
            </template>
          </v-data-table>
        </v-card-text>
    </v-container>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex';
import { getMoney, getPercent, copyObject } from '@/produto/common/functions/helpers';
import { buscarAtivosSuzano } from '@/common/resources/orcamento-tipo-verba';
import { buscarContasCorrentesElegiveis } from '../../../../common/resources/suzano-planejamento-acao-cadastro';

export default {
  props: {
    passoApuracao: {
      type: Object,
      required: true,
    },
    passoGeral: {
      type: Object,
      required: true,
    },
    objetoPassoGeralContrato: {
      type: Object,
      required: true,
    },
    configuracaoAcao: {
      type: Object,
      required: true,
    },
    passoContas: {
      type: Object,
    },
    somenteLeitura: Boolean,
    novo: Boolean,

  },
  computed: {
    ...mapGetters('metadados', [
      'getProdutoMetadado',
    ]),
    habilitaBuscar() {
      return ((this.passoGeral && this.passoGeral.status === 'EM_CADASTRO') || !this.passoGeral.status);
    },
  },
  watch: {
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      resumoAberto: false,
      tipoVerba: null,
      contasCorrentes: [],
      idTipoVerba: null,
      listaIdPeriodos: [],
      contasCorrentesSalvar: [],
      periodoApuracaoConst: 'UNICA_VEZ',
      conta: {
        valor: null,
        idContaCorrente: null,
      },
      workspace: {
        filtrosUtilizados: null,
        filtrosAbertos: null,
      },
      pagination: {
        itemsPerPage: 12,
      },
      totalPage: 0,
      intervalo: null,
      valor: null,
      headers: [
        {
          text: this.$tc('label.descricao', 1), value: 'descricaoLinha', width: '10%', align: 'center', sortable: false,
        },
        {
          text: this.$tc('label.tipo_linha_orcamento', 1), value: 'tipoOrcamento', width: '20%', align: 'center', sortable: false,
        },
        {
          text: this.$tc('label.conta_corrente', 1), value: 'nomeConta', width: '15%', align: 'center', sortable: false,
        },
        {
          text: this.$tc('label.periodo', 1), value: 'periodo', width: '15%', align: 'center', sortable: false,
        },
        {
          text: this.$tc('label.saldo_disponivel', 1), value: 'saldo', align: 'center', sortable: false,
        },
        {
          text: this.$tc('label.saldo_ajustado', 1), value: 'saldoAjustado', align: 'center', sortable: false,
        },
      ],
    };
  },
  methods: {
    getMoney,
    getPercent,
    toggleResumo() {
      if (this.passoApuracao && this.passoApuracao.valorAcao) {
        this.valor = this.passoApuracao.formaBonificacao === 'FIXO' ? getMoney(this.passoApuracao.valorAcao) : getPercent(this.passoApuracao.valorAcao);
      }
      if (this.passoApuracao && this.passoApuracao.dtaInicio && this.passoApuracao.dtaFim) {
        const dataInicio = this.moment(this.passoApuracao.dtaInicio, 'YYYY-MM-DD').format('DD/MM/YYYY');
        const dataFIm = this.moment(this.passoApuracao.dtaFim, 'YYYY-MM-DD').format('DD/MM/YYYY');
        this.intervalo = dataInicio.concat(' a ').concat(dataFIm);
        this.buscarTipoVerba();
      }
      this.resumoAberto = !this.resumoAberto;
    },
    fecharContaCorrente() {
      this.resumoAberto = false;
      this.contasCorrentes = [];
    },
    carregarInformacoes() {
      if (this.passoApuracao && this.passoApuracao.valorAcao) {
        this.valor = this.passoApuracao.formaBonificacao === 'FIXO' ? getMoney(this.passoApuracao.valorAcao) : getPercent(this.passoApuracao.valorAcao);
      }
      if (this.passoApuracao && this.passoApuracao.dtaInicio && this.passoApuracao.dtaFim) {
        const dataInicio = this.moment(this.passoApuracao.dtaInicio, 'YYYY-MM-DD').format('DD/MM/YYYY');
        const dataFIm = this.moment(this.passoApuracao.dtaFim, 'YYYY-MM-DD').format('DD/MM/YYYY');
        this.intervalo = dataInicio.concat(' a ').concat(dataFIm);
        this.buscarTipoVerba();
      }
    },
    buscarTipoVerba() {
      const focoRateio = 'CLIENTE';
      const composicaoRateio = 'CUSTOMERGROUP';
      const tipoContato = this.passoApuracao.formaBonificacao === 'FIXO' ? 'VERBA NOMINAL DE CONTRATO' : 'VERBA PERCENTUAL DE CONTRATO';
      buscarAtivosSuzano({ focoRateio, composicaoRateio }, this.$resource)
        .then((res) => {
          const tipoverbaAcaoContrato = res.data.filter((p) => p.nome.toUpperCase().includes(tipoContato));
          this.tipoVerba = tipoverbaAcaoContrato.length ? tipoverbaAcaoContrato[0].nome : '';
          this.idTipoVerba = tipoverbaAcaoContrato.length ? tipoverbaAcaoContrato[0].id : '';
        })
        .catch((err) => {
          this.$toast(err.data.error);
        })
        .finally(() => {
          if (this.passoGeral && this.passoGeral.status && this.passoGeral.status !== 'EM_CADASTRO') {
            this.buscarPeriodos();
          }
        });
    },

    buscarPeriodos() {
      const dataInicio = this.passoApuracao.dtaInicio;
      const dataFim = this.passoApuracao.dtaFim;
      const { idTipoVerba } = this;
      this.listaIdPeriodos = [];
      if (!this.validaCampos(dataInicio, dataFim, idTipoVerba)) {
        return;
      }
      this.planejamentoAcaoResource
        .buscarPeriodos({
          idTipoVerba, dataInicio, dataFim,
        })
        .then((res) => {
          res.data.forEach((el) => {
            this.listaIdPeriodos.push(el.id);
          });
        })
        .catch((err) => {
          this.$toast(err.data.error);
        })
        .finally(() => {
          if (this.listaIdPeriodos.length) {
            this.buscarContaCorrente();
          } else {
            this.$toast(this.$t('message.contrato_conta_corrente.sem_conta_corrente'));
          }
        });
    },
    buscarContaCorrente() {
      const {
        divisao,
        unidadeNegocio,
        holding,
        cliente,
        hierarquia1,
        hierarquia2,
        hierarquia3,
        produto,
      } = this.objetoPassoGeralContrato;
      const listaProdutos = [];
      if (produto && produto.length) {
        produto.forEach((el) => {
          listaProdutos.push(el.id);
        });
      }
      const parametros = {
        idDivisao: divisao.id,
        idsPeriodos: this.listaIdPeriodos,
        idUnidadeNegocio: unidadeNegocio.id,
        idHolding: (holding || {}).id,
        idCliente: (cliente || {}).id,
        buscaEmEtapas: true,
        condicao: this.passoApuracao.formaBonificacao === 'FIXO' ? null : this.passoApuracao.condicao,
        idAcaoConfiguracaoSnapshot: this.configuracaoAcao.configuracao.id,
        id_hierarquia1: (hierarquia1 || {}).id,
        id_hierarquia2: (hierarquia2 || {}).id,
        id_hierarquia3: (hierarquia3 || {}).id,
        id_produto: listaProdutos,
        dtaInicio: this.passoApuracao.dtaInicio,
        dtaFim: this.passoApuracao.dtaFim,
      };
      buscarContasCorrentesElegiveis(parametros, this.$resource)
        .then((res) => {
          const { contas } = res.data;
          this.totalPage = res.data.contas.length;
          const copia = copyObject(contas);
          this.formataObjetoTabela(contas, this.periodoApuracaoConst);
          this.formataObjetoSalvarAcao(copia, this.periodoApuracaoConst);
        })
        .catch((err) => {
          this.$toast(err.data.error);
          this.carregando = false;
        });
    },
    formataObjetoTabela(contas, periodoApuracaoConst) {
      this.contasCorrentes = contas;
      this.contasCorrentes.forEach((el) => {
        this.conta = {
          valor: this.passoApuracao.valorAcao,
          idContaCorrente: el.idContaCorrente,
        };
        el.saldoAjustado = el.saldo - this.passoApuracao.valorAcao;
        el.styleText = el.saldoAjustado < 0 ? 'color:red;' : '';
        el.valor = this.passoApuracao.valorAcao;
        el.periodoApuracao = periodoApuracaoConst;
        el.contas = [this.conta];
      });
    },
    formataObjetoSalvarAcao(contas, periodoApuracaoConst) {
      this.contasCorrentesSalvar = contas;
      this.contasCorrentesSalvar.forEach((el) => {
        this.conta = {
          valor: this.passoApuracao.valorAcao,
          idContaCorrente: el.idContaCorrente,
        };
        el.contas = [this.conta];
        el.valor = this.passoApuracao.valorAcao;
        el.periodoApuracao = periodoApuracaoConst;
        delete el.tipoOrcamento;
        delete el.tipoInvestimento;
        delete el.periodo;
        delete el.idPeriodo;
        delete el.dataFim;
        delete el.dataInicio;
        delete el.descricaoLinha;
        delete el.idContaCorrente;
        delete el.nomeConta;
        delete el.saldo;
      });
    },
    validarContasCorrentes() {
      if (this.contasCorrentes.length === 0) {
        this.$toast(this.$t('message.contrato_conta_corrente.conta_correte_indisponivel'));
        return false;
      }
      if (this.contasCorrentes.filter((p) => p.saldoAjustado < 0).length) {
        this.$toast(this.$t('message.contrato_conta_corrente.saldo_insuficiente'));
        return false;
      }
      const intervaloInicio = this.contasCorrentes.find((el) => this.moment(this.passoApuracao.dtaInicio).isBetween(el.dataInicio, el.dataFim, undefined, '[]'));
      const intervaloFim = this.contasCorrentes.find((el) => this.moment(this.passoApuracao.dtaFim).isBetween(el.dataInicio, el.dataFim, undefined, '[]'));

      const dataInicio = this.moment(this.passoApuracao.dtaInicio, 'YYYY-MM-DD');
      const dataFIm = this.moment(this.passoApuracao.dtaFim, 'YYYY-MM-DD');
      const qtdMeses = this.moment.duration({
        months: dataFIm.month() - dataInicio.month(),
      });
      if (!intervaloInicio || !intervaloFim || (qtdMeses._months + 1) !== this.contasCorrentes.length) { // eslint-disable-line
        this.$toast(this.$t('message.contrato_conta_corrente.numero_insuficiente'));
        return false;
      }
      return true;
    },
    validaCampos(dataInicio, dataFim, idTipoVerba) {
      if (!dataInicio || !dataFim || !idTipoVerba) {
        this.$toast(this.$t('message.contrato_conta_corrente.conta_corrente_buscar'));
        return false;
      }
      return true;
    },
  },
  mounted() {
    if (this.passoGeral && this.passoGeral.status && this.passoGeral.status !== 'EM_CADASTRO') {
      this.carregarInformacoes();
      this.resumoAberto = true;
    }
  },
};
</script>
