<template>
  <div class="PlanejamentoContratoFormAcoes" v-if="objetoPassoGeral">

  <planejamento-contrato-form-acoes-controle
    ref="controleAcoes"
    :alterarFinalizado="alterarFinalizado"
    :copia="copia"
    :edicao="edicao"
    :novo="novo"
    :getObjetoFormatado="getObjetoFormatado"
    :configuracao="configuracao"
    :objeto-passo-geral-contrato="objetoPassoGeral"
    :contrato-edicao="contratoEdicao"
    :pode-solicitar-aprovacao-contrato="podeSolicitarAprovacaoContrato"
    :tem-fluxo-contrato="temFluxoContrato"
    :novo-contrato="novoContrato"
    :somente-leitura="somenteLeitura"
    @PlanejamentoContratoFormAcoesControle__carregarContrato="carregarContrato"
    @PlanejamentoContratoFormAcoesControle__NovaAcao="criaNovaAcao"
    @PlanejamentoContratoFormAcoesControle__AbreForm="abreFormAcao"
    @PlanejamentoContratoFormAcoesControle__atualizarStatusContrato="atualizarStatusContrato"
    @PlanejamentoContratoFormAcoesControle__setValorContrato="setValorContrato"
    />

  <planejamento-contrato-form-acoes-visao
    ref="modalAcao"
    :alterarFinalizado="alterarFinalizado"
    :copia="copia"
    :edicao="edicao"
    :novo="novo"
    :contrato-edicao="contratoEdicao"
    :tipo-contrato="tipoContrato.nome"
    :copia-acao="copiaAcao"
    :objeto-passo-geral-contrato="objetoPassoGeral"
    :configuracao-contrato="configuracao"
    :novo-contrato="novoContrato"
    :somente-leitura="somenteLeitura"
    :campos-limitadores="camposLimitadores"
    />

  </div>
</template>
<script>
import PlanejamentoContratoFormAcoesControle from './PlanejamentoContratoFormAcoesControle';
import PlanejamentoContratoFormAcoesVisao from './PlanejamentoContratoFormAcoesVisao';

export default {
  props: {
    alterarFinalizado: Boolean,
    copia: Boolean,
    edicao: Boolean,
    novo: Boolean,
    novoContrato: Boolean,
    podeSolicitarAprovacaoContrato: Boolean,
    temFluxoContrato: Boolean,
    somenteLeitura: Boolean,
    contratoEdicao: Object,
    tipoContrato: {
      type: Object,
      required: true,
    },
    configuracao: {
      type: Object,
      required: true,
    },
    getObjetoOutroPasso: {
      type: Function,
      required: true,
    },
    camposLimitadores: Array,
  },
  components: {
    PlanejamentoContratoFormAcoesControle,
    PlanejamentoContratoFormAcoesVisao,
  },
  data: () => ({
    objetoPassoGeral: null,
    copiaAcao: false,
  }),
  methods: {
    carregarContrato(idContrato) {
      this.$emit('PlanejamentoContratoFormAcoes__carregarContrato', idContrato);
    },
    acoesCarregadas() {
      this.$refs.controleAcoes.acoesCarregadas();
    },
    setValorContrato(value) {
      this.$emit('PlanejamentoContratoFormAcoes__setValorContrato', value);
    },
    criaNovaAcao({ acao, callback, callbackValidation }) {
      this.$refs.modalAcao.novaAcao(acao, callback, callbackValidation);
    },
    abreFormAcao({
      acao, callback, callbackValidation, copia,
    }) {
      this.copiaAcao = copia;
      this.$refs.modalAcao.abreForm(acao, callback, callbackValidation);
    },
    getObjetoFormatado() {
      return this.$refs.controleAcoes.acoesParaEnvio();
    },
    validacaoAssincrona() {
      return Promise.resolve(true);
    },
    valida() {
      const validado = this.$refs.controleAcoes.qtdeAcoes() > 0;
      if (!validado) {
        this.$toast(this.$t('errors.contrato.sem_acao_cadastrada'));
        return false;
      }
      return true;
    },
    fecharTooltip() {
      this.$refs.controleAcoes.fecharTooltip();
    },
    abrePasso(confirmar) {
      this.objetoPassoGeral = this.getObjetoOutroPasso('geral');
      if (this.$refs.controleAcoes && confirmar) {
        this.$refs.controleAcoes.acoesFormatadas = [];
        this.$refs.controleAcoes.acoes = [];
      }
    },
    atualizarStatusContrato(statusContrato) {
      this.$emit('PlanejamentoContratoFormAcaoes__atualizarStatusContrato', statusContrato);
    },
    getObjetoPasso() {
      $logger.log('getObjetoPasso', 'Não implementado');
    },
  },
};
</script>
